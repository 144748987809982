<template>
  <div v-loading="loading">
    <ui-header :heading="$tc('misc.overview', 2)" v-if="department"></ui-header>
    <department-courses
      v-if="department"
      :department="department"
    ></department-courses>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import DepartmentCourses from './DepartmentCourses';
import { getDepartment } from '../api';

export default {
  data() {
    return {
      department: null,
      loading: false
    };
  },

  components: {
    UiHeader,
    DepartmentCourses
  },

  methods: {
    async getDepartment() {
      this.loading = true;
      try {
        const department = await getDepartment(this.$route.params.id);
        this.department = department;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', {
            msg: this.$tc('models.department', 1)
          }),
          type: 'error'
        };
        this.$message(msg);
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  created() {
    this.getDepartment();
  }
};
</script>
